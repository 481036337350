/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
html,
body {
  overflow-x: hidden;
}
.picsHolder {
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  max-width: 100%;
  flex: 1;
  align-items: center;
  background: linear-gradient(90deg, rgb(191, 222, 230) 0%, rgb(186, 181, 192) 100%);

  /* background: url("../../public/ResumePics/background.jpg") no-repeat; */
  background-size: 100% 100%;
}

.part1> h1{
  font-size: 8vw;
  color: rgb(77, 72, 72);
  font-family: 'Oswald', sans-serif;

}
.part1>h1 >span{
  font-family: 'Oswald', sans-serif;
  color: Black;
}
.part1>h2{
  margin: 2rem 2rem;
  font-family: 'Caveat', cursive;
  font-size: 3vw;
  color: rgb(77, 72, 72);
}


.infoHolder {
  width: 50%;
  margin: 0 2rem;
  position: relative;
  bottom: -70px;
  color: aliceblue;
}
.infoHolder > h1 {
  float: left;
  font-size: 4vw;
}
.infoHolder >h1 >span{
  /* color: aqua; */
  transform: rotateX(360deg);
}

.infoHolder > h2 {
  float: left;
  font-size: 2.5vw;
}
#picEdit {
  width: 50%;
}
#picEdit > img {
  position: relative;
  width: 70%;
  height: 80vh;
  float: left;
  border-radius: 30%;
  margin-left: 3rem;
}
.innerParag {
  margin-top: 10rem;
  font-size: 1.5vw;
  color: rgb(217, 220, 223);
  color: rgb(160, 243, 243);

}
/* .part4{
  display: none;
} */
#innerPara {
  margin-top: 6rem;

}
#Button {
  margin: 0.5rem;
}
#proj {
  color: rgb(17, 15, 15);
}
#proj:hover {
  color: rgb(17, 15, 15);
}
.animate__animated.animate__tada {
  --animate-duration: 2s;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 4s;
}
.part2{

  margin: 3.5rem 9rem;
}

/* for tablet view */
@media screen and (min-width: 480px) and (max-width: 1023px) {
  html,
  body {
    overflow-x: hidden;
  }
  .picsHolder {
    overflow: hidden;
    background: linear-gradient(90deg, rgb(191, 222, 230) 0%, rgb(186, 181, 192) 100%);

    /* background: url("../../public/ResumePics/background.jpg") no-repeat 10% 10%; */
    display: flex;
    height: 100vh;
  }

  .part1> h1{
    font-size: 10vw;
  }
  .part1>h1 >span{
    font-family: 'Oswald', sans-serif;
    color: Black;
  }
  .part1>h2{
    margin: 2rem 2rem;
    font-family: 'Caveat', cursive;
    font-size: 7vw;
  }
  .infoHolder {
    color: aliceblue;
  }
  .infoHolder > h1 {
    margin-top: -13rem;

    font-size: 7vw;
  }
  .infoHolder > h2 {
    font-size: 5vw;
  }
  #picEdit {
    align-items: center;
  }
  #picEdit > img {
    width: 90%;
    height: 50vh;
    position: relative;
    right: 70px;
  }
  .innerParag {
    display: none;
  }
  #innerPara {
    display: none;
    position: relative;
  }
  #Button {
    width: 70%;
  }
  #proj {
    color: rgb(17, 15, 15);
  }
  #proj:hover {
    color: rgb(17, 15, 15);
  }
  .animate__animated.animate__tada {
    --animate-duration: 2s;
  }
  .part4{
    display: none;
  }
  .part2{
    margin: 2rem 1.5rem;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  html,
  body {
    
    overflow-x: hidden;
  }
  .picsHolder {
    overflow: hidden;
    display: block;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(90deg, rgb(191, 222, 230) 0%, rgb(186, 181, 192) 100%);

    /* background: url("../../public/ResumePics/background.jpg") no-repeat 100% 10%; */

     /* background-size: 20000px ; */
  }
  .infoHolder {
    width: 80%;
    position: relative;
    /* top: 150px; */
    color: aliceblue;
    margin: 7rem 2rem;
    
  }

  .part1 > h1{
   
    font-size: 14vw;
    margin: 4rem 1rem;
  }
  .part1 >h1:hover{
    font-size: 12vw;
    
  }
  
  .part1 >h2{

    margin: 7rem 1rem;
    font-size: 8vw;
  }
  .part4 >h3{
    /* margin: 2rem 7.5rem; */
    color: aliceblue;
    font-family: 'Caveat', cursive;

  }
  .part4 > h3 >li{
    color: aliceblue;
    margin: 2rem 7.5rem;
    position: relative;
   bottom: 0px;
  }
  .part4 > h3{
    margin: 3rem;
    font-size: 8vw;
    position: absolute;
    bottom: 0px;
  }

  .infoHolder > h2 {
    margin-top: 60px;
    float: left;
    font-size: 8vw;
    align-content: center;
  }
  #picEdit {
    width: 50%;
  }
  #picEdit > img {
    display: none;
  }
  .innerParag {
    display: none;
  }
  #innerPara {
    width: 50%;
    height: 50vh;
    display: none;
    /* position: relative; */
    
  }
  #Button {
    display: none;
  }

  .animate__animated.animate__tada {
    --animate-duration: 3s;
  }
}

/* Smartphones (landscape) ----------- */


@media only screen and (min-width: 768px) {
 .part4{
   display: none;
 }
 .part1>h2{
   font-size: 4vw;
 }
 .part2{
   margin: 2rem 2rem;
 }
}
@media only screen and (min-width: 1500px) and (max-width:3000px) {
  
  .part1>h2{
    font-size: 6vw;
  }
  .part2{
    margin: 4rem 5rem;
  }
  #Button{
    font-size: 2vw;
    font-family: 'Oswald', sans-serif;
    border-radius:15%;

  }
 }