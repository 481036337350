.footer-container {
    /* background-color: #242424; */
    background: linear-gradient(90deg, rgb(34, 30, 30) 0%, rgb(116, 109, 79) 100%);

    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  #button{
    width: 20rem;
  }
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    display: block;
  }
  textarea {
      columns: 50;
      width: 100%;
      font-size: small;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
    align-items: center;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  /* Button css */
  .outline-success{
      width: 40rem;
  }
  .footer-link-items h2 {
    margin-bottom: 16px;
   
  }
  
  .footer-link-items > h2 {
    color: rgb(148, 138, 138);
    
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    /* margin-bottom: 5rem; */
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  .form-control{
   margin-bottom: 2rem;
   background: linear-gradient(90deg, rgb(196, 186, 186) 0%, rgb(221, 216, 216) 100%);

  }
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  .social-media a:hover{
    color: #b1b1b1;
  }
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1000px;
    /* margin: 40px auto 0 auto; */
    /* margin-right: 20rem; */
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    /* padding-left: 2rem; */
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .website-rights {
    color: #fff;
  }
  @media only screen and (max-width: 600px) {
    .footer-logo{
      display:block;
      position: relative;
      top: 0;


    }
    .social-media{
      position: relative;
      top: 0;
      left: 20px;

    }
  }
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }


  @media screen and (max-width: 768px) {

  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    .social-media{
      position: relative;
      left: 70px;


    }
  }
  