* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    
  }
  
  .home,
  .about,
  .projects,
  .resume {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .about {
    /* background-image: url('../public/images/img-2.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .projects {
    background-image: url('../public/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .resume{
    background-image: url('../public/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  .link{
    text-decoration: none;
  }
  .courseWork-items{
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 600px) {
    .educationSection{
      align-items: center;
    }
    .courseWork-items{
      display: block;
      justify-content: center;
    }
  }